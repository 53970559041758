import React, { useState, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { useParams } from 'react-router-dom';
import { Select, Input } from 'antd';
import { Loading3QuartersOutlined, CodeSandboxOutlined } from '@ant-design/icons';

import CFSQueryResult from '../../components/CFSQueryResult';
import Logo from '../../resources/svg/logo-without-text.svg';

import './index.less';

const CFSClient = (props) => {
  const { isLoading, clientQueryMasterBL, clientQueryHouseBL, fetchHouseBLDetail, updateHouseBLAppt } = props.masterBLStore;
  const { mbl, hbl, cntr } = useParams();

  const [masterBL, setMasterBL] = useState(undefined);
  const [houseBL, setHouseBL] = useState(undefined);

  // const resetQueryParamsFunc = () => {
  //   switch (true) {
  //     case !!mbl: {
  //       return {
  //         type: 'mbl',
  //         value: mbl
  //       };
  //     }
  //     case !!hbl: {
  //       return {
  //         type: 'hbl',
  //         value: hbl
  //       };
  //     }
  //     case !!cntr: {
  //       return {
  //         type: 'cntr',
  //         value: cntr
  //       };
  //     }
  //     default: {
  //       return {
  //         type: 'mbl',
  //       };
  //     }
  //   }
  // };
  const [queryParams, _setQueryParams] = useState({type: 'mbl'});
  const setQueryParams = (key) => (value) => {
    _setQueryParams({
      ...queryParams,
      [key]: value
    });
  }

  const navigateToType = (type) => (number) => {
    props.routing.push(`/${type}/${number}`);
  }

  useEffect(() => {
    setMasterBL();
    setHouseBL();
  }, [mbl, hbl, cntr]);

  useEffect(() => {
    if (!!mbl) {
      clientQueryMasterBL({ masterBill: mbl })
        .then((data) => {
          setTimeout(() => {
            setMasterBL(data);
          });
        });
    }
  }, [mbl]);

  useEffect(() => {
    if (!!hbl) {
      clientQueryHouseBL({ houseBill: hbl })
        .then((data) => {
          setTimeout(() => {
            setHouseBL(data);
          });
        });
    }
  }, [hbl]);

  useEffect(() => {
    if (!!cntr) {
      clientQueryMasterBL({ containerNumber: cntr })
        .then((data) => {
          setTimeout(() => {
            setMasterBL(data);
          });
        });
    }
  }, [cntr]);

  const valueInput = useRef();
  const onSearch = () => {
    navigateToType(queryParams.type)(queryParams.value);
  }

  const selectBefore = (
    <Select style={{width: '140px'}} value={queryParams.type} onChange={setQueryParams('type')}>
      <Select.Option value='mbl'>Master B/L</Select.Option>
      <Select.Option value='hbl'>House B/L</Select.Option>
      <Select.Option value='cntr'>Container No</Select.Option>
    </Select>
  );

  return (
    <div className='cfs-client'>
      <div className='cfs-client__top-bar'>
        <img src={Logo} height='46' style={{display: 'inline-block'}} />

        <div className='cfs-client__top-bar--main'>
          <div className='cfs-client__top-bar--header'>
            Shipment Tracking
          </div>

          <div className='cfs-client__top-bar--content'>
            Shipment availability information can be retrieved by entering any of the following and clicking on Submit
          </div>
        </div>
      </div>

      <div className='cfs-client__main'>
        <div className='cfs-client__main--top'>
          <Input className='cfs-client__main--query-input' size='large' addonBefore={selectBefore} placeholder='Please Enter your Master Bill  of Lading' ref={valueInput} value={queryParams.value} onChange={(e) => setQueryParams('value')(e.target.value)} />
          <div className='cfs-client__main--query-btn' onClick={onSearch}>Submit</div>
        </div>

        {
          isLoading ? (
            <div className='cfs-client__main--body'>
              <div className='cfs-loading'>
                <Loading3QuartersOutlined spin />
              </div>
            </div>
          ) : (
            <div className='cfs-client__main--body'>
              {
                !!masterBL || !!houseBL ? (
                  <CFSQueryResult masterBL={masterBL} houseBL={houseBL} navigateToType={navigateToType} updateHouseBLAppt={updateHouseBLAppt} />
                ) : (
                  <div className='cfs-client__main--placeholder'>
                    <CodeSandboxOutlined />
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
}

export default inject('masterBLStore', 'routing')(observer(CFSClient));
