import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

import MasterBLStore from './stores/MasterBLStore.js';

import Client from './containers/Client';

import './index.less';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const masterBLStore = new MasterBLStore();

const stores = {
  routing: routingStore,
  masterBLStore,
}

const history = syncHistoryWithStore(browserHistory, routingStore);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider {...stores}>
    <Router history={history}>
      <Client />
    </Router>
  </Provider>
);
