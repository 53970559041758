import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, DatePicker, message } from 'antd';

import moment from 'moment';

const HouseBL = (props) => {
  const { houseBill, setFeeInitialValues, updateHouseBLAppt } = props;

  const [showAppt, setShowAppt] = useState(false);
  const [appt, setAppt] = useState();

  const updateAppt = () => {
    if (!appt) {
      message.error('You need to set the appt time first.');
      return;
    }
    updateHouseBLAppt({_id: houseBill._id, pickupDate: appt.utc().endOf('day').format('MM/DD/YYYY (UTC)')});
  }

  const isAvailable = () => {
    return (!houseBill.cargoHold || houseBill.cargoHold == 'No') && houseBill.customsRelease == 'Released' && houseBill.freightCharge == 'Released' && (houseBill.masterBill.CFSChargingModel == 'HB' ? houseBill.CFSPayment == 'Paid' : houseBill.masterBill.CFSPayment == 'Paid');
  }

  return (
    <div>
      <div className='cfs-query-result__parallel-block'>
        <div className='cfs-query-result__block'>
          <div className='cfs-query-result__block-label'>
            Cargo Details
          </div>

          <div className='cfs-query-result__block-content'>
            <div className='cfs-query-result__block-content--row'>
              <label>Container Number</label>
              <span>{houseBill.masterBill.containerNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Master Bill Number</label>
              <span>{houseBill.masterBill.masterBill}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>House Bill of Lading</label>
              <span>{houseBill.houseBill}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Pieces (Man/Rec/Plt)</label>
              <span>{houseBill.pcsMan || '-'} / {houseBill.pcsRec || '-'} / {houseBill.pallets || '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Weight</label>
              <span>{houseBill.weight} LBS</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Volume</label>
              <span>{houseBill.volume} CBM</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Head Load</label>
              <span>{houseBill.headLoad}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Last Free Day</label>
              <span>{houseBill.freeTimeExpires?.freeTimeExpires > 0 ? moment(houseBill.freeTimeExpires.freeTimeExpires).format('MM/DD/YYYY') : '-'}</span>
            </div>
          </div>
        </div>

        <div className='cfs-query-result__block'>
          <div className='cfs-query-result__block-label'>
            Pick Up Requirements
          </div>

          <div className='cfs-query-result__block-content'>
            <div className='cfs-query-result__block-content--row'>
              <label>Status</label>
              {
                isAvailable() ? (
                  <span style={{ color: '#6DC16B' }}>Available</span>
                ) : (
                  <span style={{ color: '#FF0000' }}>Not available</span>
                )
              }
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Pick Up Number</label>
              <span>{houseBill.pickUpNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Customs Releases</label>
              <span>{houseBill.customsRelease}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Freight Releases</label>
              <span>{houseBill.freightCharge}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Delivery Order</label>
              <span>{houseBill.deliveryOrder}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Cargo On Hold</label>
              <span>{houseBill.cargoHold}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Marks Hold</label>
              <span>{houseBill.marksHold}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Exchange Pallets</label>
              <span>{houseBill.exchangePallets}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='cfs-query-result__btns'>
        {
          houseBill.masterBill.CFSChargingModel == 'HB' ? (
            <div className='cfs-query-result__btns--btn' onClick={() => setFeeInitialValues(houseBill.CFSFee)}>
              View Charges
            </div>
          ) : (
            isAvailable() ? (
              <div className='cfs-query-result__btns--btn' onClick={() => setShowAppt(true)}>
                Appointment for pickup
              </div>
            ) : (
              <div className='cfs-query-result__btns--btn disabled'>
                Appointment for pickup
              </div>
            )
          )
        }
      </div>

      <div className='cfs-query-result__parallel-block'>
        <div className='cfs-query-result__block'>
          <div className='cfs-query-result__block-label'>
            Shipping Information
          </div>

          <div className='cfs-query-result__block-content'>
            <div className='cfs-query-result__block-content--row'>
              <label>ATA</label>
              <span>{houseBill.ata > 0 ? moment(houseBill.ata).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>ETA</label>
              <span>{houseBill.eta > 0 ? moment(houseBill.eta).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Pick Up Agent</label>
              <span>{houseBill.pickUpAgent}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Pro Number</label>
              <span>{houseBill.proNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Pick Up Number</label>
              <span>{houseBill.pickUpNumber}</span>
            </div>
          </div>
        </div>

        <div className='cfs-query-result__block'>
          <div className='cfs-query-result__block-label' style={{visibility: 'hidden'}}>
          </div>

          <div className='cfs-query-result__block-content'>
            <div className='cfs-query-result__block-content--row'>
              <label>Ship Date</label>
              <span>{houseBill.shipDate > 0 ? moment(houseBill.shipDate).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Destination</label>
              <span>{houseBill.destination}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>IT Number</label>
              <span>{houseBill.ITNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Load Number</label>
              <span>{houseBill.loadNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Trailer Number</label>
              <span>{houseBill.trailerNumber}</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={`Estimated Pickup Charges for ${houseBill.houseBill}`}
        open={showAppt}
        okText='Submit'
        onCancel={() => setShowAppt(false)}
        onOk={updateAppt}
      >
        <div className='fee-form__datepicker--label'>
          *Please select a pickup date to estimate charges
        </div>
        <DatePicker style={{width: '100%'}} onChange={setAppt} allowClear={false} />
      </Modal>
    </div>
  );
}

HouseBL.propTypes = {
  houseBill: PropTypes.object.isRequired,
  setFeeInitialValues: PropTypes.func.isRequired,
  updateHouseBLAppt: PropTypes.func.isRequired,
};

export default HouseBL;
