import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MasterBL from './MasterBL';
import HouseBL from './HouseBL';
import PayProcessing from './PayProcessing';

import './index.less';

const CFSQueryResult = (props) => {
  const { masterBL, houseBL, navigateToType, updateHouseBLAppt } = props;

  const [feeInitialValues, setFeeInitialValues] = useState(undefined);

  return (
    <div className='cfs-query-result'>
      {
        !!houseBL ? (
          <div className='cfs-query-result__block-nav'>
            <span style={{cursor: 'pointer'}} onClick={() => navigateToType('mbl')(masterBL?.masterBill || houseBL?.masterBill.masterBill)}>{masterBL?.masterBill || houseBL?.masterBill.masterBill}</span> > <span className='cfs-query-result__block-nav--second'>{houseBL?.houseBill}</span>
          </div>
        ) : (
          <div className='cfs-query-result__block-nav'>
            { masterBL?.masterBill }
          </div>
        )
      }

      {
        feeInitialValues ? (
          <PayProcessing
            masterBill={masterBL}
            houseBill={houseBL}
            feeInitialValues={feeInitialValues}
            onCancel={() => setFeeInitialValues()}
          />
        ) : (
          !!houseBL ? (
            <HouseBL houseBill={houseBL} setFeeInitialValues={setFeeInitialValues} updateHouseBLAppt={updateHouseBLAppt} />
          ) : (
            <MasterBL masterBL={masterBL} setFeeInitialValues={setFeeInitialValues} navigateToHouseBill={navigateToType('hbl')} />
          )
        )
      }
    </div>
  );
}

CFSQueryResult.propTypes = {
  masterBL: PropTypes.object,
  houseBL: PropTypes.object,
  navigateToType: PropTypes.func.isRequired,
  updateHouseBLAppt: PropTypes.func.isRequired,
};

export default CFSQueryResult;
