import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { inject, observer } from 'mobx-react';

import CFSClient from '../CFSClient';

import './index.less';

const Client = (props) => {
  return (
    <Switch>
      <Route exact path='/'>
        <CFSClient />
      </Route>

      <Route exact path="/mbl/:mbl">
        <CFSClient />
      </Route>

      <Route exact path="/cntr/:cntr">
        <CFSClient />
      </Route>

      <Route exact path='/hbl/:hbl'>
        <CFSClient />
      </Route>

      <Redirect to='/' />
    </Switch>
  );
}

export default Client;
