import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Input, message, Form, Upload } from 'antd';
import { PaperClipOutlined, CloudUploadOutlined, CloseCircleTwoTone } from '@ant-design/icons';

import FeeForm from '../FeeForm';
import MBFeeForm from '../FeeForm/mbForm';
import PaymentQRCode from '../../resources/svg/payment-qrcode.svg';
import { currencyFormatter, fileToBase64 } from '../../utils';

import rpgRequest from '../../lib/rpgRequest.js';

const PayProcessing = (props) => {
  const [step, setStep] = useState(1);
  const [payInfo, setPayInfo] = useState({});
  const [uploadFile, setUploadFile] = useState();
  const removeUploadFile = () => {
    setUploadFile(() => {
      setTimeout(() => {
        form.resetFields(['uploadFile']);
      });
      return undefined;
    });
  }

  const { masterBill, houseBill, feeInitialValues, onCancel } = props;

  const [form] = Form.useForm();

  const onUploadFile = (file) => {
    if (file.type.includes('pdf') || file.type.includes('image')) {
      setUploadFile(file);
      return;
    }

    form.validateFields(['uploadFile']);
    return Upload.LIST_IGNORE;
  }

  const goPay = (feeId, feeInfo) => {
    if (!feeInfo.pickupDate && (masterBill || houseBill.masterBill)?.CFSChargingModel == 'HB') {
      message.error('You need to select a pick up date.');
      return;
    }

    setPayInfo({
      subject: 'CFS Fee payment certificate',
      pickupDate: `${moment(feeInfo.pickupDate).utc().endOf('day').format('MM/DD/YYYY')} (UTC)`,
      demurrage: feeInfo.detail?.demurrage?.value,
      total: feeInfo.total
    });
    setStep(2);
  }

  const goToConfirm = () => {
    setStep(3);
    setTimeout(() => {
      form.resetFields(['demurrage', 'pickupDate']);
    });
  }

  const confirmPayment = () => {
    form.validateFields()
      .then((data) => {
        fileToBase64(data.uploadFile?.file?.originFileObj, (base64Img) => {
          const requestParams = {
            _id: houseBill?._id || masterBill?._id,
            email: data.email,
            name: data.name,
            pickupDate: data.pickupDate,
            demurrage: data.demurrage,
            base64Img,
          };

          rpgRequest(`/${(masterBill || houseBill.masterBill)?.CFSChargingModel == 'HB' ? 'houseBill' : 'masterBill'}/fee/sendNotifyMailToAdmin`, 'post', requestParams)
            .then((resp) => {
              if (resp.data.code != 200) {
                try {
                  message.error(resp.data.message.map(m => m.msg).join('\n'));
                } catch(e) {
                  message.error(resp.data.message);
                }
                return;
              }

              message.success('Success!');
              setTimeout(() => {
                onCancel();
              }, 2000);
            })
            .catch((err) => {
              console.error(err);
              message.error('Server error, please try it again.');
            });
        });
      })
      .catch((err) => {
        console.info(err);
      });
  }

  const content = () => {
    switch (step) {
      case 1: {
        if ((masterBill || houseBill.masterBill)?.CFSChargingModel == 'MB') {
          return (
            <MBFeeForm
              initialValues={feeInitialValues}
              userRole='CLIENT'
              onSubmit={goPay}
              onCancel={onCancel}
            />
          );
        } else {
          return (
            <FeeForm
              houseBill={houseBill}
              initialValues={feeInitialValues}
              userRole='CLIENT'
              onSubmit={goPay}
              onCancel={onCancel}
            />
          );
        }
      }
      case 2: {
        return (
          <div className='go-to-pay'>
            <div className='go-to-pay__info'>
              Scan the QR code or click the link to complete payment！
            </div>

            <a className='go-to-pay__link' href='https://app.paycargo.com/shipandpay?region=US&vendorId=287763' target='_blank'>
              https://app.paycargo.com/shipandpay?region=US&vendorId=287763
            </a>

            <div className='go-to-pay__code-block'>
              <img src={PaymentQRCode} />

              <div className='go-to-pay__code-block--info'>
                <ul>
                  <li>Transaction Type: <b>Invoice</b></li>
                  <li>Number: <b>{houseBill?.houseBill || masterBill?.masterBill}</b></li>
                  <li>Amount：<b>{currencyFormatter(payInfo.total)}</b></li>
                </ul>
              </div>
            </div>

            <div className='go-to-pay__btns'>
              <div className='go-to-pay__btns--btn confirm' onClick={goToConfirm}>
                I have completed payment
              </div>

              <div className='go-to-pay__btns--btn back' onClick={() => setStep(1)}>
                Back
              </div>
            </div>
          </div>
        );
      }
      case 3: {
        return (
          <div className='confirm-payment'>
            <Form
              className='confirm-payment__form'
              layout='vertical'
              form={form}
              initialValues={payInfo}
            >
              <div className='confirm-payment__block'>
                <Form.Item
                  className='confirm-payment__half'
                  label='Your Name'
                  name='name'
                  rules={[
                    { required: true, message: 'Name is required.' },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  className='confirm-payment__half'
                  label='Your Email'
                  name='email'
                  rules={[
                    { required: true, message: 'Email is required.' },
                    { pattern: /^[\w\.-]+@[\w\.-]+\.\w+$/, message: 'Email does not match the format.' }
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className='confirm-payment__block'>
                <Form.Item
                  className={`confirm-payment__${(masterBill || houseBill.masterBill)?.CFSChargingModel == 'MB' ? 'full' : 'half'}`}
                  label='Subject'
                  name='subject'
                >
                  <Input disabled />
                </Form.Item>

                {
                  (masterBill || houseBill.masterBill)?.CFSChargingModel == 'HB' ? (
                    <Form.Item
                      className='confirm-payment__half'
                      label='Pickup Date'
                      name='pickupDate'
                    >
                      <Input disabled />
                    </Form.Item>
                  ) : null
                }

                {
                  (masterBill || houseBill.masterBill)?.CFSChargingModel == 'HB' ? (
                    <Form.Item
                      className='confirm-payment__half'
                      style={{display: 'none'}}
                      label='Demurrage'
                      name='demurrage'
                    >
                      <Input disabled />
                    </Form.Item>
                  ) : null
                }
              </div>

              <div className='confirm-payment__block'>
                <Form.Item
                  className='confirm-payment__uploader'
                  label='Upload'
                  name='uploadFile'
                  rules={[
                    { required: true, message: 'You can only upload a jpeg, png or pdf payment receipt screenshot.' },
                  ]}
                >
                  {
                    !!uploadFile ? (
                      <span className='confirm-payment__uploader--uploaded-file'>
                        <PaperClipOutlined style={{marginRight: '12px'}} />
                        {uploadFile.name}
                        <CloseCircleTwoTone twoToneColor='#ff4d4f' style={{marginLeft: '12px', verticalAlign: 'middle', cursor: 'pointer'}} onClick={removeUploadFile} />
                      </span>
                    ) : (
                      <Upload
                        customRequest={() => {}}
                        beforeUpload={(file) => onUploadFile(file)}
                      >
                        <div className='confirm-payment__uploader--placeholder'>
                          <CloudUploadOutlined style={{ fontSize: '36px' }} />
                          <br />
                          <br />
                          <p>Take a screenshot of your successful payment page and upload it</p>
                        </div>
                      </Upload>
                    )
                  }
                </Form.Item>
              </div>
            </Form>

            <div className='confirm-payment__btns'>
              <div className='confirm-payment__btns--btn back' onClick={() => setStep(2)}>
                Back
              </div>

              <div className='confirm-payment__btns--btn confirm' onClick={confirmPayment}>
                Submit
              </div>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <div className='cfs-query-result__pay-processing'>
      <div className='cfs-query-result__pay-processing--nav'>
        <div className={`cfs-query-result__pay-processing--nav-item ${step == 1 ? 'active' : ''}`}>1. View Charges</div>
        <div className={`cfs-query-result__pay-processing--nav-item ${step == 2 ? 'active' : ''}`}>2. Payment</div>
        <div className={`cfs-query-result__pay-processing--nav-item ${step == 3 ? 'active' : ''}`}>3. Submit Credentials</div>
      </div>

      <div className='cfs-query-result__pay-processing--content'>
        { content() }
      </div>
    </div>
  );
};

PayProcessing.propTypes = {
  masterBill: PropTypes.object.isRequired,
  houseBill: PropTypes.object.isRequired,
  feeInitialValues: PropTypes.object.isRequired,
};

export default PayProcessing;
