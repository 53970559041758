import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

const MasterBL = (props) => {
  const { masterBL, navigateToHouseBill, setFeeInitialValues } = props;

  return (
    <div>
      <div className='cfs-query-result__parallel-block'>
        <div className='cfs-query-result__block'>
          <div className='cfs-query-result__block-label'>
            Container Information
          </div>

          <div className='cfs-query-result__block-content'>
            <div className='cfs-query-result__block-content--row'>
              <label>Container Number</label>
              <span>{masterBL.containerNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Master Bill Number</label>
              <span>{masterBL.masterBill}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Customer Reference</label>
              <span>{masterBL.customerReference}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Vessel Name</label>
              <span>{masterBL.vesselName}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Vessel Departure</label>
              <span>{masterBL.vesselDeparture > 0 ? moment(masterBL.vesselDeparture).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Vessel ETA</label>
              <span>{masterBL.vesselETA > 0 ? moment(masterBL.vesselETA).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Discharged Date</label>
              <span>{masterBL.dischargeDate > 0 ? moment(masterBL.dischargeDate).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Available at Terminal</label>
              <span>{masterBL.availableAtPier > 0 ? moment(masterBL.availableAtPier).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Appointment Time</label>
              <span>{masterBL.apptTime > 0 ? moment(masterBL.apptTime).format('MM/DD/YYYY HH:mm') : '-'}</span>
            </div>
          </div>
        </div>

        <div className='cfs-query-result__block'>
          <div className='cfs-query-result__block-label' style={{visibility: 'hidden'}}>
          </div>

          <div className='cfs-query-result__block-content'>
            <div className='cfs-query-result__block-content--row'>
              <label>Status</label>
              {
                masterBL.availableAtRPG > 0 ? (
                  <span style={{ color: '#6DC16B' }}>Available</span>
                ) : (
                  <span style={{ color: '#FF0000' }}>Not available</span>
                )
              }
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Terminal Last Free Day</label>
              <span>{masterBL.pierLFD > 0 ? moment(masterBL.pierLFD).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Driver Dispatch</label>
              <span>{masterBL.driverDispatch > 0 ? moment(masterBL.driverDispatch).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Outgated From Terminal</label>
              <span>{masterBL.outgatedFromPier > 0 ? moment(masterBL.outgatedFromPier).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Arrived at RPG</label>
              <span>{masterBL.arrivedAtRPG > 0 ? moment(masterBL.arrivedAtRPG).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>Available at RPG</label>
              <span>{masterBL.availableAtRPG > 0 ? moment(masterBL.availableAtRPG).format('MM/DD/YYYY') : '-'}</span>
            </div>

            {/*
              <div className='cfs-query-result__block-content--row'>
                <label>LCL Free Time Expires</label>
                <span>{masterBL.freeTimeExpires?.freeTimeExpires > 0 ? moment(masterBL.freeTimeExpires?.freeTimeExpires).format('MM/DD/YYYY') : '-'}</span>
              </div>
            */}

            <div className='cfs-query-result__block-content--row'>
              <label>Return Empty Date</label>
              <span>{masterBL.emptyReturnDate > 0 ? moment(masterBL.emptyReturnDate).format('MM/DD/YYYY') : '-'}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
              <label>RPG Reference Number</label>
              <span>{masterBL.RPGReferenceNumber}</span>
            </div>

            <div className='cfs-query-result__block-content--row'>
            </div>
          </div>

          {
            masterBL.CFSChargingModel == 'MB' ? (
              <div className='cfs-query-result__btns no-bottom-margin'>
                <div className='cfs-query-result__btns--btn' onClick={() => setFeeInitialValues(masterBL.CFSFee)}>
                  View Charges
                </div>
              </div>
            ) : null
          }
        </div>
      </div>

      <div className='cfs-query-result__block'>
        <div className='cfs-query-result__block-label'>
          House B/L Status List
        </div>

        <div className='cfs-query-result__block-content'>
          <table className='cfs-query-result__list-table'>
            <thead>
              <tr>
                <td>HBL Number</td>
                <td>AMS HBL Number</td>
                <td>Pcs</td>
                <td>Pkg</td>
                <td>LBS</td>
                <td>CBM</td>
                <td>Description</td>
                <td>ITD</td>
                <td>HL</td>
                <td>Mks Hold</td>
                <td>Ship Date</td>
              </tr>
            </thead>

            <tbody>
              {
                (masterBL?.houseBillList || []).map((houseBill) => {
                  return (
                    <tr key={houseBill._id}>
                      <td>
                        <a onClick={() => navigateToHouseBill(houseBill.houseBill)}>{houseBill.houseBill}</a>
                      </td>
                      <td>{houseBill.amsNumber}</td>
                      <td>{houseBill.pcsRec}</td>
                      <td>{houseBill.pkgType}</td>
                      <td>{houseBill.weight}</td>
                      <td>{houseBill.volume}</td>
                      <td>{houseBill.description}</td>
                      <td>{houseBill.destination}</td>
                      <td>{houseBill.headLoad}</td>
                      <td>{houseBill.marksHold}</td>
                      <td>{houseBill.shipDate > 0 ? moment(houseBill.shipDate).format('MM/DD/YYYY') : '-'}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

MasterBL.propTypes = {
  masterBL: PropTypes.object.isRequired,
  navigateToHouseBill: PropTypes.func.isRequired,
};

export default MasterBL;
